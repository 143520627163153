import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const buscarLinha = async (payload, page) => {
    const { data } = await apiInstance.post(ENDPOINTS.BUSCAR_LINHA + `?page=${page}`, payload);
    return data
}

export const criarLinha = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.CRIAR_LINHA, payload);
    return data
}

export const editarLinha = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.EDITAR_LINHA + `/${id}`, payload);
    return data
}

export const exibirLinha = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.EXIBIR_LINHA + `/${id}`);
    return data
}

export const excluirLinha = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.EXCLUIR_LINHA + `/${id}`);
    return data
}

export const listarLinha = async () => {
    const { data } = await apiInstance.post(ENDPOINTS.LISTAR_LINHA);
    return data
}

